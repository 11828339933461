


#analysis-container  .student_column {
    width:400px !important;
    height:75px !important;
    max-width:400px !important;
    max-height:75px !important;
    min-width:150px !important;
    min-height:75px !important;
  }

