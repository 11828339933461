.indexedStep {
  color: white;
  width: 35px;
  height: 35px;
  font-size: 20px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: rgba(0, 116, 217, 1);
}
.RSPBprogressBar .RSPBstep{
  position:relative;
  transform: translateX(0%) scale(1) !important;

} 