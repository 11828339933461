@import url("font-awesome.min.css");
@import url("loading.css");
@import url("custom.css");
@import url("NavMenu.css");

.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

img{
  max-width: 100% !important;
  max-height: 100% !important;
}
.se-image-container , figure{
width: 100% !important;
height: 100% !important;
}
.height{

  overflow: hidden;
}
.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.footer {
  background-color: #7f9183;
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
}

.jumbotron {
  padding: 70px 30px 70px 30px;
  margin: 0px auto;
  border-radius: 0px !important;
  background: #03589b !important ;
  color: floralwhite;
}

address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

body {
  background-color: aliceblue;
}

.navbar-dark {
  background-color: #082d0f;
  border-radius: 0px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modal-dialog {
    max-width: 99% !important;
    margin: auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .modal-dialog {
    max-width: 95% !important;
    margin: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .modal-dialog {
    max-width: 95% !important;
    margin: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .modal-dialog {
    max-width: 90% !important;
    margin: auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .modal-dialog {
    max-width: 80% !important;
    margin: auto;
  }
}
.react-toast-notifications__container {
  z-index: -1;
  margin-top: 60px;
}

.bg-question {
  background-color: #03589b;
}

.normal {
  background-color: #ffffff !important;
  border: none;
  border-radius: 10px !important;
}
.gradingMode {
  background-color: #9df3b1 !important;
  border: 4px solid #098434 !important;
  border-radius: 10px !important;
}

.choose-it {
  cursor: pointer;
  background-color: #f3e39d !important;
  border: 4px solid #847209 !important;
  border-radius: 10px !important;
}

.selected {
  cursor: pointer;
  background-color: #9df3b1 !important;
  border: 4px solid #098434 !important;
  border-radius: 10px !important;
}

.not-selected {
  cursor: pointer;
  background-color: #ffffff !important;
  border: none;
  border-radius: 10px !important;
}
.correct-answer {
  background-color: #9df3b1 !important;
  border: 4px solid #098434 !important;
  border-radius: 10px !important;
}

.wrong-answer {
  background-color: #f39d9d !important;
  border: 4px solid #840909 !important;
  border-radius: 10px !important;
}

.fs-1 {
  font-size: 1.2rem;
}

.fileContainer {
  position: relative;
}

.deleteFile {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.File_Img {
  font-size: 2.5rem;
  border-radius: 5px;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-radius: 10px !important;
}
.File_PDF {
  font-size: 6rem;
}

.text-shadow {
  text-shadow: -2px -3px 16px rgba(143, 240, 255, 1);
}

.sun-editor {
  width: 100% !important;
  overflow: auto !important;
  display: block;
  border-radius: 5px !important;
}

.isCorrectInput {
  border: 5px solid rgb(43, 218, 43);
}
.isWrongtInput {
  border: 5px solid red;
}
.isWrong{
background-color: #f7777b;
border: 4px solid #f02128!important;
border-radius: 10px!important;
}
.pointer {
  cursor: pointer;
}

.customModal {
  background: #fdfeff;
  max-width: 1600px !important;
  width: 100%;
}
.customModal_2 {
  background: #fdfeff;
  max-width: 800px !important;
  width: 100%;
  z-index: 2000 !important;
}